import * as React from "react";
import { Link } from "gatsby";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { Image } from "semantic-ui-react";
import * as tootiLogo from "../images/tooti.png";
// import * as tootiNotLogo5 from "../images/tooti-not5.png";
// import * as tootiNotLogo from "../images/tooti-not.png";
import * as highIcon from "../images/high.png";
import * as lowIcon from "../images/low.png";
import * as moderateIcon from "../images/moderate.png";

import { withLayout, LayoutProps, menuItems } from "../components/Layout";
import {
  Button,
  Segment,
  Container,
  Grid,
  Header,
  Icon,
} from "semantic-ui-react";

const IndexPage = (props: LayoutProps) =>
  <div>
    {/* Master head */}
    <Segment vertical inverted textAlign="center" className="masthead">
      <HeaderMenu
        Link={Link} pathname={props.location.pathname} items={menuItems} inverted
      />
      <Container text>
        <Header inverted as="h1">tooti - food insight app</Header>
        <Header inverted as="h2">Provides instant food insight in an easy way</Header>
        
      </Container>
    </Segment>

  {/* About this starter */}
  <Segment vertical className="stripe">
      <Grid stackable verticalAlign="middle" className="container">
        <Grid.Row>
          <Grid.Column width="8">
            <h2>Why did we develop tooti?</h2>
            <p><b>Problem:</b></p>
            <br></br>
            <p>It is hard to read and understand packaged food labels.</p>
            <br></br>
            <br></br>
            <p><b>Solution:</b></p>
            <p>tooti...</p>
            <br></br>
            <br></br>
            <h2>What tooti does?</h2>
            <p>The app delivers an easy and intuitive insight to packaged food.</p> 
            <p>tooti's advanced algorithm highlights packaged food’s nutrition and ingredients.</p>         
          </Grid.Column>          
        </Grid.Row>
      </Grid>
    </Segment>

    {/* About this starter */}
    <Segment vertical className="stripe">
      <Grid stackable verticalAlign="middle" className="container">
        <Grid.Row>
          <Grid.Column width="8">
          <h3>How does it work?</h3>
            <p>Login to tooti.</p>
            <p>Scan packaged food's barcode.</p>
            <p>Get insight about the food in your package.</p>
          <h3>Why use tooti?</h3>
            <p>tooti provides two ratings: One for nutrition and the other for ingredients.</p>
            <p>Each rating is composed of strawberries.
            If you receive 5 strawberries, then the food is good for average health.
            If you receive less strawberries, please read the additional information provided by the app.
            If you receive five gray strawberry think twice.</p>
            <p>tooti also features ingredients and nutrition facts that you may not be aware of.</p>     
            <br></br>
            <p>*Disclaimer - Currently, tooti supports only USA products.</p>
          </Grid.Column>          
        </Grid.Row>
      </Grid>
    </Segment>

    {/* Key features */}
    <Segment vertical className="stripe alternate feature">
      <Grid columns="3" textAlign="center" divided relaxed stackable className="container">
        <Grid.Row>
          <Grid.Column>
            <Header icon>
            Food rating
            <br/><br/>
              <Image src={tootiLogo} size="mini" circular />
              <Image src={tootiLogo} size="mini" circular />
              <Image src={tootiLogo} size="mini" circular />
              <Image src={tootiLogo} size="mini" circular />
              <Image src={tootiLogo} size="mini" circular />
              <br/> <br/>
            </Header>
            <p>Know at a glance<br/>overall food's health score</p>
          </Grid.Column>
          <Grid.Column>
            <Header icon>
            Nutrition highlight!
            <br/><br/>
            <Image src={highIcon} size="mini" circular /> <label>High risk</label>
            <br/>
            <Image src={moderateIcon} size="mini" circular /> <label>Moderate risk</label>
            <br/>
            <Image src={lowIcon} size="mini" circular /> <label>No known risk</label>
            <br/><br/>
            </Header>
            <p>tooti highlights a few nutritional facts.</p>
          </Grid.Column>
          <Grid.Column>
            <Header icon>
            Ingredient insight!
            <br/><br/>
            <Image src={highIcon} size="mini" circular /> <label>High risk</label>
            <br/>
            <Image src={moderateIcon} size="mini" circular /> <label>Moderate risk</label>
            <br/>
            <Image src={lowIcon} size="mini" circular /> <label>No known risk</label>
            <br/><br/>
            </Header>
            <p>tooti emphasizes and details risky ingredients.</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </div>;

export default withLayout(IndexPage);
